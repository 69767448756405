import type { JsonFilter } from '@/hooks/use-table-filter/types';
import type { ModelName } from '@pigello/pigello-matrix';
import { DateTime } from 'luxon';

export interface IEnumChoice {
  title: string;
  filters: {
    operator: IOperator;
    value: string;
  }[];
  overrideExternalFieldName?: string;
  badgeVariant:
    | 'default'
    | 'gray'
    | 'light-gray'
    | 'green'
    | 'yellow'
    | 'blue'
    | 'purple'
    | 'orange'
    | 'cyan'
    | 'destructive'
    | 'outline'
    | null
    | undefined;
}
export interface IFilter {
  kind: IFilterKind;
  title: string;
  externalFieldName: string;
  choices?: { v: string | number | boolean; d: string }[];
  enumChoices?: IEnumChoice[];
  relationModelName?: ModelName;
  operators: IOperator[];
  defaultOperator: IOperator;
  relationDefaultFilters?: JsonFilter;
}

export type IFilterValue = string | number | string[] | boolean | null;

interface IDefaultFilter {
  kind: IFilterKind;
  operators: IOperator[];
  defaultOperator: IOperator;
}
type IFilterKind =
  | typeof RELATION_FILTER
  | typeof STRING_FILTER
  | typeof DATE_FILTER
  | typeof BOOLEAN_FILTER
  | typeof NUMBER_FILTER
  | typeof TAG_FILTER
  | typeof NULL_FILTER
  | typeof SELECT_FILTER
  | typeof ENUM_FILTER;

export interface IAppliedFilter {
  operator: IOperator;
  externalFieldName: string;
  value: IFilterValue;
}

export const RELATION_FILTER = 're' as const;
export const STRING_FILTER = 'st' as const;
export const SELECT_FILTER = 'se' as const;
export const DATE_FILTER = 'dt' as const;
export const BOOLEAN_FILTER = 'bo' as const;
export const NUMBER_FILTER = 'no' as const;
export const TAG_FILTER = 'tg' as const;
export const NULL_FILTER = 'nu' as const;
export const ENUM_FILTER = 'en' as const; // special case for i.e statuses on contracts, always rendered

export type IOperator =
  | '__in'
  | '__in!'
  | '__iexact'
  | '__iexact!'
  | '__icontains'
  | '__icontains!'
  | '__lte'
  | '__lte!'
  | '__gte'
  | '__gte!'
  | '__lt'
  | '__lt!'
  | '__gt'
  | '__gt!'
  | '__isnull'
  | '__isnull!'
  | 'noop';

export const OPERATORS: Record<string, IOperator[]> = {
  IN: ['__in', '__in!'], // in for arrays / booleans
  EXACT: ['__iexact', '__iexact!'], // exact for strings/numbers
  CONTAINS: ['__icontains', '__icontains!'],
  LTE: ['__lte', '__lte!'], // less than or equal for dates/numbers
  GTE: ['__gte', '__gte!'], // greater than or equal for dates/numbers
  LT: ['__lt', '__lt!'], // less than for dates/numbers
  GT: ['__gt', '__gt!'], // greater than for dates/numbers
  ISNULL: ['__isnull', '__isnull!'], // null/not null
  NOOP: ['noop'], // for when we arent allowed to add any operators, no-operation
};

export const getReadableOperator = (operator: IOperator, kind: IFilterKind) => {
  switch (operator) {
    case '__in': {
      if (kind === BOOLEAN_FILTER) {
        return 'är';
      } else {
        return 'innehåller';
      }
    }
    case '__in!': {
      if (kind === BOOLEAN_FILTER) {
        return 'är inte ';
      } else {
        return 'innehåller inte';
      }
    }
    case '__iexact':
      return 'är';
    case '__iexact!':
      return 'är inte';
    case '__icontains':
      return 'innehåller';
    case '__icontains!':
      return 'innehåller inte';
    case '__lte': {
      switch (kind) {
        case 'dt':
          return 'innan eller lika med';
        default:
          return 'mindre än eller lika med';
      }
    }
    case '__lte!': {
      switch (kind) {
        case 'dt':
          return 'inte innan eller lika med';
        default:
          return 'inte mindre än eller lika med';
      }
    }
    case '__gte': {
      switch (kind) {
        case 'dt':
          return 'efter eller lika med';
        default:
          return 'mer än eller lika med';
      }
    }
    case '__gte!': {
      switch (kind) {
        case 'dt':
          return 'inte efter eller lika med';
        default:
          return 'inte mer än eller lika med';
      }
    }
    case '__lt': {
      switch (kind) {
        case 'dt':
          return 'innan';
        default:
          return 'mindre än';
      }
    }
    case '__lt!': {
      switch (kind) {
        case 'dt':
          return 'inte innan';
        default:
          return 'inte mindre än';
      }
    }
    case '__gt': {
      switch (kind) {
        case 'dt':
          return 'efter';
        default:
          return 'mer än';
      }
    }
    case '__gt!': {
      switch (kind) {
        case 'dt':
          return 'inte efter';
        default:
          return 'inte mer än';
      }
    }
    case '__isnull':
      return 'har inget värde';
    case '__isnull!':
      return 'har ett värde';
    case 'noop':
      return 'är';
  }
};

export const defaultRelationFilter: IDefaultFilter = {
  kind: RELATION_FILTER,
  operators: [...OPERATORS.IN],
  defaultOperator: OPERATORS.IN[0],
};

export const defaultStringFilter: IDefaultFilter = {
  kind: STRING_FILTER,
  operators: [...OPERATORS.CONTAINS, ...OPERATORS.EXACT, ...OPERATORS.ISNULL],
  defaultOperator: OPERATORS.CONTAINS[0],
};

export const defaultDateFilter: IDefaultFilter = {
  kind: DATE_FILTER,
  operators: [
    ...OPERATORS.LTE,
    ...OPERATORS.GTE,
    ...OPERATORS.IN,
    ...OPERATORS.LT,
    ...OPERATORS.GT,
  ],
  defaultOperator: OPERATORS.IN[0],
};

export const defaultNumberFilter: IDefaultFilter = {
  kind: NUMBER_FILTER,
  operators: [
    ...OPERATORS.LTE,
    ...OPERATORS.GTE,
    ...OPERATORS.LT,
    ...OPERATORS.GT,
  ],
  defaultOperator: OPERATORS.GTE[0],
};

export const defaultBooleanFilter: IDefaultFilter = {
  kind: BOOLEAN_FILTER,
  operators: [...OPERATORS.IN],
  defaultOperator: OPERATORS.IN[0],
};
export const defaultNullFilter: IDefaultFilter = {
  kind: NULL_FILTER,
  operators: [...OPERATORS.ISNULL],
  defaultOperator: OPERATORS.ISNULL[1],
};

export const defaultTagFilter: IDefaultFilter = {
  kind: TAG_FILTER,
  operators: OPERATORS.NOOP,
  defaultOperator: OPERATORS.NOOP[0],
};

export const defaultSelectFilter: IDefaultFilter = {
  kind: SELECT_FILTER,
  operators: [...OPERATORS.IN, ...OPERATORS.EXACT],
  defaultOperator: OPERATORS.IN[0],
};

export const defaultEnumFilter: IDefaultFilter = {
  kind: ENUM_FILTER,
  operators: OPERATORS.IN,
  defaultOperator: OPERATORS.IN[0],
};

export const customFilterConfig = {
  custom_invoice_filter: {
    credit_rental_invoices: 'rental_invoice=true&total_value__lte=-1',
    credit_invoices: 'rental_invoice=false&total_value__lte=-1',
    not_attested: `attested_date__isnull=true`,
    reminders: `attested_date__isnull=false&closed_time__isnull=true&reminder_amount__gte=1&due_date__lt=${DateTime.now().toISODate()}`,
    overdue: `attested_date__isnull=false&closed_time__isnull=true&due_date__lt=${DateTime.now().toISODate()}`,
    fully_paid: `attested_date__isnull=false&closed_time__isnull=false`,
    overdue_not_handled: `attested_date__isnull=false&closed_time__isnull=true&reminder_amount=0&due_date__lt=${DateTime.now().toISODate()}`,
    sent: `attested_date__isnull=false&closed_time__isnull=true&due_date__gte=${DateTime.now().toISODate()}&distribution_date__isnull=false`,
    not_sent: `attested_date__isnull=false&closed_time__isnull=true&due_date__gte=${DateTime.now().toISODate()}&distribution_date__isnull=true`,
  },
  custom_verification_group_filter: {
    attested: `confirmation_date__isnull=false`,
    not_attested: `confirmation_date__isnull=true`,
  },
  custom_signable_doc_filter: {
    initiated_not_started: `signing_initiated=true&signing_started_time__isnull=true`,
    not_initiated: `signing_initiated=false&external_sign_engine__isnull=false`,
    manual: `signing_initiated=false&external_sign_engine__isnull=true`,
  },
  custom_sensor_filter: {
    not_matched: `apartment__isnull=true&parking_spot__isnull=true&industrial_premises__isnull=true&common_area__isnull=true`,
  },
  custom_payments_filter: {
    distributed: `invoice__isnull=false&annulled=false`,
    not_handled: `invoice__isnull=true&original_invoice__isnull=true&debt_invoice__isnull=true&annulled=false`,
    // not_matched: `invoice__isnull=true&debt_invoice__isnull=true&tenant__isnull=false&original_invoice__isnull=false`, // TODO - needs user "or" filter to check if user is null and original invoice is not null OR if user is not null and original invoice is null
    annulled: `annulled=true`,
    overpayment: `original_invoice__isnull=false&invoice__isnull=true&debt_invoice__isnull=true&tenant__isnull=false`, // TODO - needs user "or" filter to check all user types null
    matched_manual: `matched_date__isnull=true&invoice__isnull=true&annulled=false&original_invoice__isnull=false`,
  },
  custom_vacancyinvoice_filter: {
    attested: `attested_date__isnull=false`,
    not_attested: `attested_date__isnull=true`,
    annulled: `annulled=true`,
  },
};
