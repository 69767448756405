import { NextResponse } from 'next/server';
import { DEFAULT_REVALIDATE_TIME } from '../constants';
import { getHeaders } from '../headers';
import type { FetchApi } from '../types';

export const fetchApi = async ({
  method = 'GET',
  url,
  body,
  next: { revalidate, tags } = {},
  req,
  signal,
}: FetchApi) => {
  const { headers } = await getHeaders(req);
  if (!headers) {
    return NextResponse.json(
      {
        error: {
          status: 401,
          statusText: 'Ej behörig',
        },
        url,
      },
      { status: 401, statusText: 'Ej behörig' }
    );
  }
  const isBodyStringified = !!body && typeof body === 'string';
  const response = await fetch(url, {
    method: method.toUpperCase(),
    next: {
      revalidate: revalidate ?? DEFAULT_REVALIDATE_TIME,
      tags,
    },
    signal,
    headers: { ...headers },
    ...(!!body && {
      body: isBodyStringified ? body : JSON.stringify(body),
    }),
  });
  return response;
};

type CustomErrorMessage = {
  errorMessage?: string;
};

async function api<T>(props: FetchApi & CustomErrorMessage) {
  const response = await fetchApi(props);

  if (!response.ok) {
    throw {
      status: response.status,
      message: props.errorMessage ?? response.statusText,
      url: response.url,
      errorData: await response.json().catch(() => undefined),
    };
  }
  const json = (await response.json()) as T;

  return json;
}

export function getApi<T>(
  props: Omit<FetchApi, 'method'> & CustomErrorMessage
) {
  return api<T>({ ...props, method: 'GET' });
}

export function postApi<T>(
  props: Omit<FetchApi, 'method'> & CustomErrorMessage
) {
  return api<T>({ ...props, method: 'POST' });
}

export function patchApi<T>(
  props: Omit<FetchApi, 'method'> & CustomErrorMessage
) {
  return api<T>({ ...props, method: 'PATCH' });
}

export function deleteApi<T>(
  props: Omit<FetchApi, 'method'> & CustomErrorMessage
) {
  return api<T>({ ...props, method: 'DELETE' });
}
