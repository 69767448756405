import type {
  BaseInstance,
  IBaseInstanceConfig,
  ModelName,
} from '@pigello/pigello-matrix';
import type {
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { get } from './api/get';
import { getCount } from './api/get-count';
import { getList } from './api/get-list';
import type {
  ErrorResponse,
  ListArguments,
  UseGenericMutateOptions,
  useCreateGenericInstanceProps,
  useDeleteGenericInstanceProps,
  useGetGenericInfinteListProps,
  useGetGenericInstanceProps,
  useGetGenericListProps,
  useMutateGenericInstanceProps,
} from './types';

const DEFAULT_STATE_TIME = 60 * 5 * 1000;

export const useGetInstance = <C extends BaseInstance>(
  options: useGetGenericInstanceProps<C> & {
    modelName: ModelName;
    config?: IBaseInstanceConfig<C>;
  }
) => {
  return useQuery({
    queryKey: [
      options.modelName,
      {
        modelName: options.modelName,
        id: options.id,
        nested: options.nested,
      },
    ],
    queryFn: async () => {
      const res = await get({
        modelName: options.modelName,
        config: options.config,
        id: options.id,
        nested: options.nested,
        overrideUrl: options.overrideUrl,
      });
      return res;
    },
    refetchOnWindowFocus: false,
    staleTime: DEFAULT_STATE_TIME,
    ...options,
  });
};

export const useGetList = <C extends BaseInstance>(
  options: useGetGenericListProps<C> & {
    modelName?: ModelName;
    config?: IBaseInstanceConfig<C>;
  }
) =>
  useQuery({
    queryKey: [
      options.modelName,
      'list',
      {
        modelName: options.modelName,
        nested: options.nested,
        queryParams: { ...options.queryParams },
        overrideUrl: options?.overrideUrl,
        overrideUrlReplaceAll: options?.overrideUrlReplaceAll,
        ...options.queryParams,
      },
    ],
    queryFn: async ({ signal }) => {
      const res = await getList({
        modelName: options.modelName,
        config: options.config,
        nested: options.nested,
        queryParams: {
          ...options.queryParams,
        },
        fetchAllManyRelations: options.fetchAllManyRelations,
        overrideUrl: options?.overrideUrl,
        overrideUrlReplaceAll: options?.overrideUrlReplaceAll,
        signal,
      });
      return res.data;
    },
    refetchOnWindowFocus: false,
    staleTime: DEFAULT_STATE_TIME,
    ...options,
  });

export const useGetInfiniteList = <C extends BaseInstance>(
  options: useGetGenericInfinteListProps<C> & {
    modelName: ModelName;
    config?: IBaseInstanceConfig<C>;
  }
) =>
  useInfiniteQuery({
    ...options,
    queryKey: [
      options.modelName,
      'list',
      {
        modelName: options.modelName,
        nested: options.nested,
        queryParams: { ...options.queryParams },
        overrideUrl: options?.overrideUrl,
        overrideUrlReplaceAll: options?.overrideUrlReplaceAll,
        ...options.queryParams,
      },
    ],
    queryFn: async ({ pageParam, signal }) => {
      const res = await getList({
        modelName: options.modelName,
        config: options.config,
        nested: options.nested,
        queryParams: {
          ...options.queryParams,
          page: pageParam,
        },
        overrideUrl: options?.overrideUrl,
        overrideUrlReplaceAll: options?.overrideUrlReplaceAll,
        signal,
      });
      return res.data;
    },
    getNextPageParam: (lastPage) => {
      if (lastPage?.meta && lastPage.meta?.page >= lastPage.meta?.page_amount) {
        return undefined;
      }
      return (lastPage.meta?.page ?? 0) + 1;
    },
    staleTime: DEFAULT_STATE_TIME,
    refetchOnWindowFocus: false,
    initialPageParam: 1,
  });

export const useMutateGenericInstance = <Instance extends BaseInstance>(
  mutationFn: (
    options: useMutateGenericInstanceProps<Instance>
  ) => Promise<Instance>,
  options: UseMutationOptions<Instance, ErrorResponse, unknown, unknown> &
    UseGenericMutateOptions,
  modelName: ModelName
) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn,
    onSuccess: (data, variables, context) => {
      // TODO: Make the invalidation more advanced
      options.onSuccess?.(data, variables, context);
      // Invalidate the instance query
      queryClient.invalidateQueries({
        queryKey: [modelName, { modelName, id: data.id }],
      });
      // Invalidate the list query
      queryClient.invalidateQueries({
        queryKey: [
          modelName,
          'list',
          {
            modelName,
          },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [modelName, 'allinstances'],
      });
      queryClient.invalidateQueries({
        queryKey: [modelName, 'allids'],
      });
      if (options.invalidateQueries) {
        options.invalidateQueries.forEach((modelName) => {
          queryClient.invalidateQueries({
            queryKey: [modelName, { modelName: modelName, id: data.id }],
          });
          queryClient.invalidateQueries({
            queryKey: [
              modelName,
              'list',
              {
                modelName: modelName,
              },
            ],
          });
          queryClient.invalidateQueries({
            queryKey: [modelName, 'allinstances'],
          });
          queryClient.invalidateQueries({
            queryKey: [modelName, 'allids'],
          });
        });
      }
    },
  });
};

export const useCreateGenericInstance = <Instance extends BaseInstance>(
  mutationFn: (
    options: useCreateGenericInstanceProps<Instance>
  ) => Promise<Instance>,
  options: UseMutationOptions<Instance, ErrorResponse, unknown, unknown> &
    UseGenericMutateOptions,
  modelName: ModelName
) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn,
    onSuccess: (data, variables, context) => {
      options.onSuccess?.(data, variables, context);
      // Invalidate the instance query
      queryClient.invalidateQueries({ queryKey: [modelName, { modelName }] });
      // Invalidate the tablelist query
      queryClient.invalidateQueries({
        queryKey: [
          modelName,
          'list',
          {
            modelName,
          },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [modelName, 'allinstances'],
      });
      if (options.invalidateQueries) {
        options.invalidateQueries.forEach((modelName) => {
          queryClient.invalidateQueries({
            queryKey: [modelName, { modelName: modelName, id: data.id }],
          });
          queryClient.invalidateQueries({
            queryKey: [
              modelName,
              'list',
              {
                modelName: modelName,
              },
            ],
          });
        });
      }
    },
  });
};

export const useDeleteGenericInstance = <Instance extends BaseInstance>(
  mutationFn: (
    options: useDeleteGenericInstanceProps<Instance>
  ) => Promise<Instance>,
  options: UseMutationOptions<Instance, ErrorResponse, unknown, unknown> &
    UseGenericMutateOptions,
  modelName: ModelName
) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn,
    onSuccess: (data, variables, context) => {
      // TODO: Make the invalidation mmore advanced
      options.onSuccess?.(data, variables, context);
      // Invalidate the instance query
      queryClient.invalidateQueries({ queryKey: [modelName, { modelName }] });
      // Invalidate the tablelist query
      queryClient.invalidateQueries({
        queryKey: [
          modelName,
          'list',
          {
            modelName,
          },
        ],
      });
      if (options.invalidateQueries) {
        options.invalidateQueries.forEach((modelName) => {
          queryClient.invalidateQueries({
            queryKey: [modelName, { modelName: modelName, id: data.id }],
          });
          queryClient.invalidateQueries({
            queryKey: [
              modelName,
              'list',
              {
                modelName: modelName,
              },
            ],
          });
        });
      }
    },
  });
};

export const useGetCount = (
  options: {
    modelName: ModelName;
    filters?: ListArguments['queryParams']['filters'];
    revalidate?: number;
    overrideUrl?: string;
    overrideUrlReplaceAll?: boolean;
  } & Omit<UseQueryOptions<unknown, unknown, number>, 'queryKey' | 'queryFn'>
) => {
  return useQuery({
    queryKey: [
      options.modelName,
      'count',
      {
        modelName: options.modelName,
        filters: options.filters,
      },
    ],
    queryFn: async ({ signal }) => {
      const res = await getCount({
        modelName: options.modelName,
        filters: options.filters,
        signal,
      });
      return res;
    },
    refetchOnWindowFocus: false,
    staleTime: DEFAULT_STATE_TIME,
    retry: 1,
    ...options,
  });
};
