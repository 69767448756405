import type { GlobalFilterItem } from '@/components/global-filter/global-filter-modal';
import { fetchApi } from '@/requests/api';
import { BASE_BACKEND_URL } from '@/requests/constants';
import { getCookies } from '@/requests/cookies';
import type { Meta } from '@/requests/types';
import {
  CompanyConfig,
  RealEstateConfig,
  type Company,
  type RealEstate,
} from '@pigello/pigello-matrix';
import { setCookie } from 'cookies-next';
import queryString from 'query-string';

export const addGlobalRealestateFilter = (realestateId: string) =>
  addGlobalFilter('global_realestates', realestateId);

export const removeGlobalRealestateFilter = (realestateId: string) =>
  removeGlobalFilter('global_realestates', realestateId);

export const addGlobalSegmentFilter = (segmentId: string) =>
  addGlobalFilter('global_segments', segmentId);

export const removeGlobalSegmentFilter = (segmentId: string) =>
  removeGlobalFilter('global_segments', segmentId);

type GlobalFilter = {
  [key: string]: string[];
  global_realestates: string[];
  global_segments: string[];
  filter_external_clients: string[];
  global_companies: string[];
};

function getPageParams(page = 1, pageSize = 100) {
  return queryString.stringify({
    _page: page,
    _page_size: pageSize,
  });
}
export const getCurrentGlobalFilters = async () => {
  const { cookies } = await getCookies();

  let currentFiltering: GlobalFilter = {
    global_realestates: [],
    global_segments: [],
    filter_external_clients: [],
    global_companies: [],
  };

  let currentFilteringStr = cookies.globalFilter;

  if (!currentFilteringStr)
    currentFilteringStr = JSON.stringify({
      global_realestates: [],
      global_segments: [],
      filter_external_clients: [],
      global_companies: [],
    });

  try {
    currentFiltering = JSON.parse(currentFilteringStr);
  } catch (err) {
    console.log('unable to parse global filters! falling back...');
  }

  return currentFiltering;
};

export const resetGlobalFilters = () => {
  setCookie(
    'globalFilter',
    JSON.stringify({
      global_realestates: [],
      global_segments: [],
      filter_external_clients: [],
      global_companies: [],
    })
  );
};

const setCurrentGlobalFilters = (filtering: GlobalFilter) => {
  setCookie('globalFilter', JSON.stringify(filtering));
};

export const addGlobalFilters = async (filterItems: GlobalFilterItem[]) => {
  resetGlobalFilters();

  const currentFiltering = await getCurrentGlobalFilters();

  if (filterItems?.length) {
    for (let i = 0; i < filterItems.length; i++) {
      const current = filterItems[i];
      const key = current.type;
      const id = current.id;

      if (currentFiltering[key].includes(id)) continue;

      currentFiltering[key] = [...currentFiltering[key], id];
    }
  }

  setCurrentGlobalFilters(currentFiltering);
};

export const addGlobalFilter = async (key: string, id: string) => {
  const currentFiltering = await getCurrentGlobalFilters();

  if (currentFiltering[key].includes(id)) return;

  currentFiltering[key] = [...currentFiltering[key], id];

  setCurrentGlobalFilters(currentFiltering);
};

const removeGlobalFilter = async (key: string, id: string) => {
  const currentFiltering = await getCurrentGlobalFilters();

  if (!currentFiltering[key].includes(id)) return;

  currentFiltering[key].splice(currentFiltering[key].indexOf(id), 1);

  setCurrentGlobalFilters(currentFiltering);
};

export const getGlobalFilterQuery = async (skipGlobalFilters?: boolean) => {
  const { cookies } = await getCookies();
  let query = `&organization=${cookies.organization_id}`;

  if (skipGlobalFilters) {
    return query;
  }

  const currentFiltering = await getCurrentGlobalFilters();

  // handle the fact that we actually cant filter on external clients - TODO use backend filter (not existing yet)
  if (currentFiltering.filter_external_clients?.length > 0) {
    try {
      const companyUrl = `${BASE_BACKEND_URL}/${CompanyConfig.listUrl}/?${getPageParams()}&managed_by__in=${currentFiltering.filter_external_clients.join(',')}`;
      const companies = await fetchApi({
        url: companyUrl,
      });

      const companyData = (await companies.json()) as {
        meta: Meta;
        data: Company[];
      };

      const companyIds = companyData.data.map((c: Company) => c.id);

      if (companyIds?.length > 0) {
        const realEstates = await fetchApi({
          url: `${BASE_BACKEND_URL}/${RealEstateConfig.listUrl}/?${getPageParams()}&owned_by__in=${companyIds.join(',')}`,
        });
        const realEstateData = (await realEstates.json()) as {
          meta: Meta;
          data: RealEstate[];
        };
        const realEstateIds = realEstateData.data.map((r: RealEstate) => r.id);

        if (realEstateIds?.length > 0) {
          currentFiltering.global_realestates = [
            ...(currentFiltering.global_realestates || []),
            ...realEstateIds,
          ];
        }
      }
    } catch (e) {
      console.error('Couldnt filter by external clients: ', e);
    }
  }

  // handle the fact that we actually cant filter on companies :D - TODO use backend filter (not existing yet)
  if (currentFiltering.global_companies?.length > 0) {
    try {
      const companyIds = currentFiltering.global_companies;

      const realEstates = await fetchApi({
        url: `${BASE_BACKEND_URL}/${RealEstateConfig.listUrl}/?${getPageParams()}&owned_by__in=${companyIds.join(',')}`,
      });
      const realEstateData = (await realEstates.json()) as {
        meta: Meta;
        data: RealEstate[];
      };
      const realEstateIds = realEstateData.data.map((r: RealEstate) => r.id);
      if (realEstateIds?.length > 0) {
        currentFiltering.global_realestates = [
          ...(currentFiltering.global_realestates || []),
          ...realEstateIds,
        ];
      }
    } catch (e) {
      console.error('Couldnt filter by companies: ', e);
    }
  }
  for (const key in currentFiltering) {
    if (currentFiltering[key]?.length === 0) continue;
    query += `&${key}=${currentFiltering[key].join(',')}`;
  }

  return query;
};
